import env from "../env";

export default () => {
  return new Promise((resolve, reject) => {
    // if (Math.random() > 0.95) {
    //   reject("Something went wrong.");

    //   return;
    // }

    let storage = localStorage.getItem(env.storage);
    let notes = JSON.parse(storage) || [];

    setTimeout(() => {
      resolve(notes);
    }, 500);
  });
};
