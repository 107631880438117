import env from "../env";

export default item => {
  return new Promise((resolve, reject) => {
    // if (Math.random() > 0.95) {
    //   reject("Something went wrong.");

    //   return;
    // }

    let storage = localStorage.getItem(env.storage);
    let notes = JSON.parse(storage) || [];
    let ids = notes.map(item => item._id);

    item._id = ids.length ? Math.max(...ids) + 1 : 1;

    let string = JSON.stringify([...notes, item]);

    localStorage.setItem(env.storage, string);

    setTimeout(() => {
      resolve(item);
    }, 500);
  });
};
