import React from "react";
import "./index.css";
import { useSelector } from "react-redux";

function Loader() {
  let loader = useSelector(state => state.loader);

  let getLoaderClasses = () => {
    return "loader" + (loader ? "" : " hidden");
  };

  return (
    <div className={getLoaderClasses()}>
      <span className="bg-light text-primary">Please wait...</span>
    </div>
  );
}

export default Loader;
