import React, { useRef } from "react";
import "./index.css";
import { useDispatch, useSelector } from "react-redux";
import {
  notesAdd,
  loaderShow,
  loaderHide,
  modalNewShow,
  modalNewHide,
  modalErrorShow,
  errorContentSet
} from "../../actions";
import { Button, Modal, Form } from "react-bootstrap";
import handleAddNote from "../../functions/handleAddNote";

function New() {
  let dispatch = useDispatch();
  let showModal = useSelector(state => state.modalNew);
  let elementTitle = useRef(null);
  let elementContent = useRef(null);

  let handleAdd = () => {
    let title = elementTitle.current.value || "[Untitled]";
    let content = elementContent.current.value || "[Empty]";
    let item = { title, content };

    dispatch(loaderShow());

    handleAddNote(item)
      .then(item => {
        dispatch(notesAdd(item));
      })
      .catch(error => {
        dispatch(errorContentSet(error));

        dispatch(modalErrorShow());
      })
      .finally(() => {
        dispatch(modalNewHide());

        dispatch(loaderHide());
      });
  };

  return (
    <div className="new">
      <Button variant="success" onClick={() => dispatch(modalNewShow())}>
        Add new
      </Button>

      <Modal show={showModal} onHide={() => dispatch(modalNewHide())}>
        <Modal.Header closeButton>
          <Modal.Title>Add a new note</Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <Form>
            <Form.Group>
              <Form.Label>Title</Form.Label>

              <Form.Control
                ref={elementTitle}
                type="text"
                placeholder="Note title"
              />
            </Form.Group>

            <Form.Group>
              <Form.Label>Content</Form.Label>

              <Form.Control
                ref={elementContent}
                as="textarea"
                rows="3"
                placeholder="Note Content"
              />
            </Form.Group>
          </Form>
        </Modal.Body>

        <Modal.Footer>
          <Button variant="secondary" onClick={() => dispatch(modalNewHide())}>
            Close
          </Button>

          <Button variant="success" onClick={() => handleAdd()}>
            Save
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
}

export default New;
