import React from "react";
import "./index.css";
import { useSelector, useDispatch } from "react-redux";
import {
  notesRemove,
  loaderShow,
  loaderHide,
  modalErrorShow,
  errorContentSet
} from "../../actions";
import { Card, Dropdown } from "react-bootstrap";
import handleRemoveNote from "../../functions/handleRemoveNote";

function Notes() {
  let dispatch = useDispatch();
  let notes = useSelector(state => state.notes);

  let handleRemove = item => {
    dispatch(loaderShow());

    handleRemoveNote(item)
      .then(() => {
        dispatch(notesRemove(item));
      })
      .catch(error => {
        dispatch(errorContentSet(error));

        dispatch(modalErrorShow());
      })
      .finally(() => {
        dispatch(loaderHide());
      });
  };

  let renderNotes = () => {
    if (notes.length) {
      return [...notes].reverse().map((item, index) => (
        <div className="note" key={index}>
          <Card>
            <Card.Header as="h5">
              <div className="note-index">#{notes.length - index}</div>

              <div className="note-title">{item.title}</div>

              <Dropdown alignRight>
                <Dropdown.Toggle variant="Secondary">&#8942;</Dropdown.Toggle>

                <Dropdown.Menu>
                  <Dropdown.Item onClick={() => handleRemove(item)}>
                    Remove
                  </Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            </Card.Header>

            <Card.Body>
              <Card.Text>{item.content}</Card.Text>
            </Card.Body>
          </Card>
        </div>
      ));
    } else {
      return <div className="note">No notes to see.</div>;
    }
  };

  return <div className="notes">{renderNotes()}</div>;
}

export default Notes;
