import env from "../env";

export default item => {
  return new Promise((resolve, reject) => {
    // if (Math.random() > 0.95) {
    //   reject("Something went wrong.");

    //   return;
    // }

    let storage = localStorage.getItem(env.storage);
    let notes = JSON.parse(storage) || [];
    let filtered = notes.filter(note => note._id !== item._id);
    let string = JSON.stringify(filtered);

    localStorage.setItem(env.storage, string);

    setTimeout(() => {
      resolve();
    }, 500);
  });
};
