import React, { useEffect } from "react";
import "./index.css";
import Header from "../header";
import New from "../new";
import Notes from "../notes";
import Footer from "../footer";
import Error from "../error";
import Loader from "../loader";
import { useDispatch, useSelector } from "react-redux";
import {
  notesSet,
  loaderShow,
  loaderHide,
  modalErrorShow,
  errorContentSet
} from "../../actions";
import "bootswatch/dist/flatly/bootstrap.min.css";
import handleAuthUser from "../../functions/handleAuthUser";
import handleGetNotes from "../../functions/handleGetNotes";

function App() {
  let dispatch = useDispatch();
  let errorContent = useSelector(state => state.errorContent);

  useEffect(() => {
    dispatch(loaderShow());

    handleAuthUser()
      .then(() => {
        handleGetNotes()
          .then(notes => {
            dispatch(notesSet(notes));
          })
          .catch(error => {
            dispatch(errorContentSet(error));

            dispatch(modalErrorShow());
          });
      })
      .catch(error => {
        dispatch(errorContentSet(error));

        dispatch(modalErrorShow());
      })
      .finally(() => {
        dispatch(loaderHide());
      });
  });

  return (
    <React.Fragment>
      <Header />

      <div className="app container bg-light">
        <New />

        <Notes />
      </div>

      <Footer />

      <Error content={errorContent} />

      <Loader />
    </React.Fragment>
  );
}

export default App;
