const errorContentReducer = (state = "", action) => {
  switch (action.type) {
    case "ERROR_CONTENT_SET":
      return action.payload;
    default:
      return state;
  }
};

export default errorContentReducer;
