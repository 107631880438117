import React from "react";
import "./index.css";
import { useDispatch, useSelector } from "react-redux";
import { modalErrorHide } from "../../actions";
import { Button, Modal } from "react-bootstrap";

function Error(props) {
  let dispatch = useDispatch();
  let showError = useSelector(state => state.modalError);

  return (
    <Modal show={showError} onHide={() => dispatch(modalErrorHide())}>
      <Modal.Header closeButton>
        <Modal.Title>Error!</Modal.Title>
      </Modal.Header>

      <Modal.Body>{props.content}</Modal.Body>

      <Modal.Footer>
        <Button variant="secondary" onClick={() => dispatch(modalErrorHide())}>
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

export default Error;
