import notesReducer from "./notes";
import loaderReducer from "./loader";
import modalNewReducer from "./modalNew";
import modalErrorReducer from "./modalError";
import errorContentReducer from "./errorContent";
import { combineReducers } from "redux";

const allReducers = combineReducers({
  notes: notesReducer,
  loader: loaderReducer,
  modalNew: modalNewReducer,
  modalError: modalErrorReducer,
  errorContent: errorContentReducer
});

export default allReducers;
