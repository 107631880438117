/* --------- Notes --------- */

export const notesSet = items => {
  return {
    type: "NOTES_SET",
    payload: items
  };
};

export const notesAdd = item => {
  return {
    type: "NOTES_ADD",
    payload: item
  };
};

export const notesRemove = item => {
  return {
    type: "NOTES_REMOVE",
    payload: item
  };
};

/* --------- Loader --------- */

export const loaderShow = () => {
  return {
    type: "LOADER_SHOW"
  };
};

export const loaderHide = () => {
  return {
    type: "LOADER_HIDE"
  };
};

/* --------- New Modal --------- */

export const modalNewShow = () => {
  return {
    type: "MODAL_NEW_SHOW"
  };
};

export const modalNewHide = () => {
  return {
    type: "MODAL_NEW_HIDE"
  };
};

/* --------- Error Modal --------- */

export const modalErrorShow = () => {
  return {
    type: "MODAL_ERROR_SHOW"
  };
};

export const modalErrorHide = () => {
  return {
    type: "MODAL_ERROR_HIDE"
  };
};

/* --------- Error Content --------- */

export const errorContentSet = value => {
  return {
    type: "ERROR_CONTENT_SET",
    payload: value
  };
};
