// import env from "../env";

export default () => {
  return new Promise((resolve, reject) => {
    // if (Math.random() > 0.95) {
    //   reject("Something went wrong.");

    //   return;
    // }

    setTimeout(() => {
      resolve();
    }, 500);
  });
};
