import React from "react";
import "./index.css";

function Footer() {
  return (
    <div className="footer bg-primary text-light">
      Created using React and Redux - 2019
    </div>
  );
}

export default Footer;
